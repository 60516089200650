import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import articles from './data/articles';
import videos from './data/videos';

const Home = () => {

  return (
    <div className="container">
       <div className="d-flex">  
       <div className="flex-grow-1">
            <h5>YouTube Videos..</h5>
            {videos.map((video, index) => (
                          <div key={index}>
                          <a href={video.link} target="_blank" rel="noopener noreferrer">
                            {video.title}
                          </a>
                          <hr id='v-links'></hr>
                        </div>
            ))}
          </div> 
          <div className="flex-grow-2">
            <section id='about'> 
              <img src='scary-skull-logo.gif' alt='background-image'></img>         
  
            <p>Hi, I'm Bhekinkosi Daweti, a passionate Full-Stack Developer, skilled in building dynamic web applications.  
            <p> My expertise include React, Django, Node.js, Express, Bootstrap, and RESTful APIs.</p>  
               I specialize in developing seamless user interfaces and scalable backend solutions.</p>
            <p>With a strong foundation in web development, I create high-performance applications  
               that provide a smooth user experience.</p>            
            </section>  
                
          </div>

          <div className="flex-grow-1">
            <section id='articles'>
            <h5>Articles</h5>
            <ul>
              {articles.map((article, index) => (
                <li key={index}>
                  <p>{article.title}</p>
                  <p>{article.summary}</p>
                  <a href={article.link} target="_blank" rel="noopener noreferrer">Read more</a>
                  <hr></hr>
                </li>              
              ))}
            </ul>
            
            </section>
          </div>        
    </div>
  </div>
  );
}

export default Home;
