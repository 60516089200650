import React from 'react';
import skills from "./data/skills";



const Skills = () => {
  return (
    <div className="container">
    <div className="row">
       <div className="col-sm">
        
        </div>

        <div className='col-sm'>  
    
        <section id="skills">       
        <h2>Skills</h2>
        <h3>Tech Skills</h3>
		{skills.map((skill, index) => (
                <img 
                  key={index} 
                  src={skill.image} 
                  alt={'skills'} 
                  className="skill-icons"
                  style={{ width: "100px", height: "auto", cursor: "pointer", margin: "5px", border: "3px solid black", borderRadius: "3px"}}
                />
              ))}
      
          <hr></hr>
    
		</section>
    </div>
    <div className='col-sm'>
      
    </div>
    </div>
    </div>
  );
}

export default Skills;
