import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
const AppointmentForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
     <div className='container'>
      <div className='row'>

      <div className='col-sm'>      
      </div>

        <div className='col-sm'>
		<form
		  name="contact"
		  method="POST"
		  data-netlify="true"
		  onSubmit="submit"
		>
			  <input type="hidden" name="form-name" value="contact" />
				  <div class="form-group">
					<label for="exampleFormControlInput1">Name</label>
					<input type="text" name="name" className="form-control" id="exampleFormControlInput1" placeholder="name"  onChange={handleChange} required />
			  </div>
			  
			  <div class="form-group">
				<label for="exampleFormControlInput1">Email address</label>
				<input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" onChange={handleChange} required />
			  </div>
					
			  <div class="form-group">
				<label for="exampleFormControlTextarea1">Message</label>
				<textarea class="form-control" id="exampleFormControlTextarea1" rows="3" textarea name="message" onChange={handleChange} required></textarea>
			</div>
		  <button className="btn btn-success" type="submit">Send</button>
		</form>
		</div>
		
	  <div className='col-sm'>      
      </div>
	  
	  </div>
	</div>
	
  );
};

export default AppointmentForm;
