import React, {useState} from "react";
import projects from "./data/projects";


const Footer = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Open modal with selected image
    const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
    };

    // Close modal
    const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
};
    return (
        <div className="footer">
            <h4 className="m-b3">Current work...</h4>
            {projects.map((project, index) => (
                <img 
                  key={index} 
                  src={project.image} 
                  alt={`Project ${index + 1}`} 
                  className="project-screenshot" 
                  onClick={() => openModal(project.image)}
                  style={{ width: "100px", height: "auto", cursor: "pointer", margin: "5px" }}
                />
              ))}
                 {/* Modal */}
                {showModal && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-btn" onClick={closeModal}>&times;</span>
                        <img src={selectedImage} alt="Selected project" className="modal-image" />
                    </div>
                </div>
            )}
            <p> Made with ❤️</p>
        </div>
    );
};
export default Footer;