const skills = [
  { image: '/skills-icon/bootstrap.png' },
  { image: '/skills-icon/css.png' },
  { image: '/skills-icon/django.png' },
  { image: '/skills-icon/express.png' },
  { image: '/skills-icon/git.png' },
  { image: '/skills-icon/html.png' },
  { image: '/skills-icon/javascript.png' },
  { image: '/skills-icon/mongodb.png' },
  { image: '/skills-icon/posgres.png' },
  { image: '/skills-icon/react.png' },
  { image: '/skills-icon/cloud.png' },
];

export default skills;
