import React, { useState } from "react";
import html2canvas from "html2canvas";

const ValentinesECard = () => {
  const [message, setMessage] = useState("Happy Valentine's Day! 💖");
  const [bgColor, setBgColor] = useState("#ffcccc");
  const [emoji, setEmoji] = useState("❤️");

  const downloadCard = () => {
    const card = document.getElementById("ecard");
    html2canvas(card).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "valentine-ecard.png";
      link.click();
    });
  };

  return (
    <div className="flex flex-col items-center p-4">
      <h1 className="text-2xl font-bold mb-4">Valentine's E-Card Generator 💌</h1>
      
      <div id="ecard" className="w-80 h-48 flex items-center justify-center rounded-lg shadow-lg p-4"
        style={{ backgroundColor: bgColor }}>
        <p className="text-xl font-semibold text-center">{emoji} {message} {emoji}</p>
      </div>
      
      <div className="mt-4 space-y-2">
        <input 
          type="text" 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          placeholder="Type your message..." 
          className="border p-2 rounded w-full"
        />
        <input 
          type="color" 
          value={bgColor} 
          onChange={(e) => setBgColor(e.target.value)}
          className="w-full"
        />
        <select 
          value={emoji} 
          onChange={(e) => setEmoji(e.target.value)}
          className="border p-2 rounded w-full"
        >
          <option value="❤️">❤️</option>
          <option value="💖">💖</option>
          <option value="😍">😍</option>
          <option value="💘">💘</option>
        </select>
        <button 
          onClick={downloadCard} 
          className="bg-red-500 text-black px-4 py-2 rounded mt-2"
        >
          Download E-Card
        </button>
      </div>
    </div>
  );
};

export default ValentinesECard;
