import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faHotel, faShop } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import React from 'react';

//Keep onlu summary of the projects and create links to Giyhub via icons

const Projects = () => {
  return (

    <div className='container'>
      <div className='row'>

      <div className='col-sm'>      
      </div>

        <div className='col-sm'>

        <h1>Projects</h1>   

        <h3>FlagQuiz App <FontAwesomeIcon icon={faFlag} /></h3> 
        <p>Summary: Flag quiz app that users can play by matching a country name with a country flag. This project has got two versions a 
		Django-based version and a React version (the currently deployed version).
          <br></br>The Django version uses vanilla JavaScript in the frontend.   
		  </p>

          <a href="https://github.com/bhekidaweti/capstone" target="_blank" rel="noopener noreferrer">
          source code for the Django version
          </a> <br></br>
		  <a href="https://github.com/bhekidaweti/flag-quiz-json" target="_blank" rel="noopener noreferrer">
          source for the React version
          </a> <br></br>
		  <a href="https://funwithworldflags.com" target="_blank" rel="noopener noreferrer">
          live demo
          </a>
          <hr></hr>
		  
		 <h3>Digwamaje Grave Services </h3> 
        <p>Summary: A React web app created as business website for a clinet with more than five pages, a navbar and a footer. The service includes 
		domain management, on-going technical support and SEO implementation.     
		</p>
          <a href="https://github.com/bhekidaweti/web" target="_blank" rel="noopener noreferrer">
          source code
          </a> <br></br>
		  <a href="https://digwamajegraveservices.co.za" target="_blank" rel="noopener noreferrer">
          live demo
          </a>
          <hr></hr>
 

        <h3>Booking System <FontAwesomeIcon icon={faHotel} /></h3>
        <p>Summary: This is a booking system web app for a guest house built with Django, HTML, CSS and JavaScript </p>

        <a href="https://github.com/bhekidaweti/booking-project" target="_blank" rel="noopener noreferrer">
        source
          </a>
          <hr></hr>
      
        <h3>Online Shop <FontAwesomeIcon icon={faShop} /></h3>
        <p>Summary: This is a fully functional online shopping website with PayPal and FastPay payment integration.</p>

        <a href="https://github.com/bhekidaweti/dee-shop" target="_blank" rel="noopener noreferrer">
        source code
          </a>
          <hr></hr>
       
        <h3>Django React Car Dealership <FontAwesomeIcon icon={faCar} /></h3>
        <p>Summary: Django backend and React frontend project for a car dealership website.
          <br></br>This is a full stack application utilizing the Django RestFramework API 
        </p>

        <a href="https://github.com/bhekidaweti/car-dealer-django-react" target="_blank" rel="noopener noreferrer">
        source code
          </a>
          <hr></hr>
		  <h5> A couple of some of my cool projects and the contributions I do on Open Source projects can be accessed via my github page</h5>
      </div>

      <div className='col-sm'>
      
      </div>

      </div>       
    </div>
  );
}

export default Projects;
